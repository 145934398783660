import React, { useEffect, useState } from 'react';
import StickyHeader from '../StickyHeader';
import { MODULE_PATH } from '../../constants/Module';
import GcashLogo from '../../assets/svg/home-booking/gcash_logo.svg';
import IconInsurnce from '../../assets/svg/home-booking/icon_insurance.svg';
import IconMotorcycleGray from '../../assets/svg/home-booking/icon_motorcycle_gray.svg';
import IconPackageGray from '../../assets/svg/home-booking/icon_package_gray.svg';
import IconPeso from '../../assets/svg/home-booking/icon_peso.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FooterBtn from '../FooterBtn';
import { useDispatch, useSelector } from 'react-redux';
import { BOOK_ANY, COURIER_NAME } from '../../utils/enums/AppConstants';
import BOOKANY_IMAGE from '../../assets/svg/icon_base_book-any-courier.svg';
import { COURIER_IMAGES } from '../../utils/enums/CourierImages';
import { STANDARD_COURIERS } from '../../utils/enums/StandardDelivery';
import { capitalizeName, formatBills, isEmpty } from '../../utils/helpers/purefunctions';
import { setDriversTip } from '../../redux/onDemand/actions';
import Voucher from '../../../app/body/Payment/Voucher';
import PaymentDetails from '../PaymentDetails';
import { PERCENT_BASE_FEE } from '../../utils/enums/DeliveryConstants';
import DriverTipList from '../DriversTipList';
import { Flex, Select, Spin, message } from 'antd';
import { useNavigate } from 'react-router';
import { setLoader } from '../../redux/home/actions';
import { setItemPrice as standardItemPrice } from '../../redux/standard/actions';
import { setItemPrice as onDemandItemPrice } from '../../redux/onDemand/actions';

const PaymentSummary = ({
  useCashBack,
  cashBack,
  handleCashBackSwitch,
  voucherAmount,
  setVoucherAmount,
  shippingFee,
  convenienceFee,
  rate,
  payWithGCash,
}) => {
  const dispatch = useDispatch();

  const [tip, setTip] = useState(0);
  const [voucherValid, isVoucherValid] = useState(true);
  const [isPaymentCliked, setIsPaymentClicked] = useState(false);
  const [declaredValue, setDeclaredValue] = useState('');
  const [showDriversTipDrawer, setShowDriversTipDrawer] = useState(false);

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);
  const product = standardProps.product;
  const { formTab, targetOrder } = useSelector(state => state.homeReducer);

  const isScheduledServiceType = STANDARD_COURIERS.includes(targetOrder.courierId);
  const isStandard = formTab === 'standard-view';
  const { courier: onDemandcourier, vehicleType } = onDemandProps;
  const isBookAny = onDemandcourier.courier === BOOK_ANY;

  const courierRate = isStandard ? standardProps.courier.rate : onDemandcourier.rate;
  const courierType = isStandard ? { ...standardProps.courier } : { ...onDemandcourier };
  const refNo = isStandard ? standardProps.orderId : onDemandProps.orderId;

  const serviceType =
    isScheduledServiceType || isStandard ? standardProps.product.value : vehicleType.vehicleType;

  const standardDeclaredValue = (PERCENT_BASE_FEE / 100) * declaredValue;
  const standardAdditionalFees = isStandard
    ? isNaN(standardDeclaredValue)
      ? 0
      : Number(standardDeclaredValue)
    : 0;

  const additonalFees = parseFloat(convenienceFee) + parseFloat(tip);

  const totalRate = parseFloat(rate) + parseFloat(additonalFees) + standardAdditionalFees;
  const totalShippingFee = shippingFee === 0 ? Number(courierRate) : shippingFee;

  const courierInsurance = isBookAny ? 'Assigned Courier' : COURIER_NAME[courierType.courier];
  const isForAddTip = !isBookAny && !isStandard && courierType?.courier === 'LALAMOVE';

  const handleChangeDeclaredValue = e => {
    const { value } = e.target;

    if (value === '0') return setDeclaredValue('');
    if (!isEmpty(value)) {
      if (isNaN(value)) {
        return message.error('Please enter only numbers.', 3.0);
      }
      const num = String(Math.floor(value));

      const hasDecimal = String(value).includes('.');
      const [_, decimal] = String(value)?.split('.');

      if (hasDecimal) {
        if (decimal.length > 2) return;
      }

      if (num.length > 7) {
        return message.error('Field value should only contain 7 whole numbers.', 3.0);
      }
      return setDeclaredValue(parseFloat(value));
    }
    return setDeclaredValue(parseFloat(value));
  };

  const onDeclaredValue = isStandard ? standardItemPrice : onDemandItemPrice;

  const onClickPayment = () => {
    if (!voucherValid) {
      return message.error('Invalid Voucher code', 3.0);
    }
    dispatch(setDriversTip(tip));
    dispatch(onDeclaredValue(declaredValue));
    setIsPaymentClicked(true);
    payWithGCash(parseFloat(totalRate) - parseFloat(rate));
  };

  return (
    <div className="summary-container">
      <StickyHeader title="Payment Summary" onPrevNavigate={{ path: MODULE_PATH.HOME }} />
      <div className="summary-content">
        <div className="courier__details">
          <div className="order-details">
            <div className="courier-price">
              <img
                src={isBookAny ? BOOKANY_IMAGE : COURIER_IMAGES[courierType.courier]}
                alt="Grab logo"
              />
              <div className="courier-service">
                <span>{isBookAny ? 'Book Any Courier' : COURIER_NAME[courierType.courier]}</span>
                {isStandard ? (
                  <div className="description">
                    <img src={IconPackageGray} alt="package" />
                    <span>
                      {' '}
                      {`${capitalizeName(product.value?.toLowerCase())} • ${
                        product?.description?.weightText || ''
                      }`}{' '}
                    </span>
                  </div>
                ) : (
                  <div className="description">
                    <img src={IconMotorcycleGray} alt="motorcycle" />
                    <span>{capitalizeName(serviceType?.toLowerCase())}</span>
                    <img src={IconPackageGray} alt="package" />
                    <span>{vehicleType.description.weightText}</span>
                  </div>
                )}
              </div>
              <span className="price">
                <img src={IconPeso} alt="Peso" />
                <span>{formatBills(courierRate)}</span>
              </span>
            </div>
            <div className="declare-value">
              <FontAwesomeIcon icon={solid('peso-sign')} />
              <input
                type="number"
                placeholder="Declared Value"
                value={declaredValue}
                onChange={e => handleChangeDeclaredValue(e)}
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </div>

            <div className="insurance">
              <img src={IconInsurnce} alt="Insurance" />
              <span>Assigned courier's insurance is automatically applied to your item.</span>
            </div>
          </div>
          <div className="insurance-option">
            <span>Item Insurance Option</span>
            <Select
              placeholder="Add Insurance"
              className="select-option"
              disabled={true}
              defaultValue={`${courierInsurance} Insurance`}
            />
          </div>
        </div>
        {isForAddTip && (
          <div className="drivers-tip-container">
            <div className="title">Driver's Tip Option</div>
            <div className="drivers-tip" onClick={() => setShowDriversTipDrawer(true)}>
              <span>{tip ? `P ${parseFloat(tip)?.toFixed(2)} ` : 'Add a tip'}</span>
              <FontAwesomeIcon icon={solid('angle-down')} />
            </div>
          </div>
        )}
        <DriverTipList
          show={showDriversTipDrawer}
          onSetDriversTipDrawer={() => setShowDriversTipDrawer(false)}
          tip={tip}
          setTip={setTip}
        />
        {!isStandard && (
          <Voucher
            setVoucherAmount={setVoucherAmount}
            shippingFee={totalShippingFee}
            voucherAmount={Number(voucherAmount)}
            isBookAny={isBookAny}
            provider={isBookAny ? '' : COURIER_NAME[courierType.courier]}
            isVoucherValid={valid => isVoucherValid(valid)}
            refNo={refNo}
          />
        )}

        <PaymentDetails
          totalAmount={totalRate}
          shippingFee={totalShippingFee}
          convenienceFee={Number(convenienceFee)}
          tip={tip}
          voucherAmount={Number(voucherAmount)}
          cashBack={cashBack}
          useCashBack={useCashBack}
          handleCashBackSwitch={handleCashBackSwitch}
        />
        <div className="privacy__policy">
          <div className="policy-and-conditions">
            By pressing the <b>Pay with GCash</b> button below, I agree with the
            <span> Privacy Policy </span> and <span> Terms and Conditions </span> of Parcels PH.
          </div>
          <div className="gcash-wallet">
            <img src={GcashLogo} alt="GCash Logo" />
            <span>
              Your<b> GCash wallet </b> will be charge <b>upon booking</b>
            </span>
          </div>
        </div>
      </div>
      {isPaymentCliked && <Spin fullscreen size="large" dotSizeLG={40} />}
      <FooterBtn
        className={`summary-payment ${!voucherValid || isPaymentCliked ? 'disabled' : ''}`}
        childClass="radius"
        label="Pay with GCash"
        onClick={onClickPayment}
      />
    </div>
  );
};

export default PaymentSummary;
