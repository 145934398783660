import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLoader } from '../../../shared/redux/home/actions';
import { setCurrentUser } from '../../../shared/redux/users/actions';
import PaymentDAO from '../../../shared/utils/dao/PaymentDAO';
import OnDemandDAO from '../../../shared/utils/dao/DPH';
import AddressBookDAO from '../../../shared/utils/dao/AddressBook';
import { createOrderPayload, isEmpty } from '../../../shared/utils/helpers/purefunctions';
import { MODULE_PATH } from '../../../shared/constants/Module';
import { message } from 'antd';
import {
  BOOK_ANY,
  COURIER,
  DEFAULT_REASON,
  DELIVERY_TYPE_VIEW,
  DEPLOYMENT,
  STAGING_AND_PRE_PROD_ENV,
  ZONE,
} from '../../../shared/utils/enums/AppConstants';
import PlacingOrder from '../../../shared/components/v2/PlacingOrder';
import Login from '../../../shared/utils/dao/Login';
import { CONVENIENCE_FEE } from '../../../shared/utils/enums/DeliveryConstants';

import { setOnDemandInitialState } from '../../../shared/redux/onDemand/actions';
import { setStandardInitialState } from '../../../shared/redux/standard/actions';

const PaymentProcess = ({
  userInfo,
  onDemandProps,
  standardProps,
  recentlyAddress,
  view,
  setOnDemandCourier,
  setCurrentUser,
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [happyPath, setHappyPath] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bookingCreated, setBookingCreated] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const formTab = useSelector(state => state.homeReducer.formTab);

  const isStandard = formTab === DELIVERY_TYPE_VIEW.standard;
  const paymentStatusPagePath = isStandard
    ? MODULE_PATH.PAYMENT.STANDARD.MAIN
    : MODULE_PATH.PAYMENT.ON_DEMAND.MAIN;

  /* uncomment this for testing in local*/
  // const tempCreateOrder = async () => {
  //   const { pickUp, dropOff, orderId, courier } = onDemandProps;
  //   const { senderDetails } = pickUp;
  //   const { recipientDetails } = dropOff;

  //   let courierMode, advancedCourierMode;
  //   const statusPayment = 'SUCCESS';
  //   const isProd = STAGING_AND_PRE_PROD_ENV.includes(ZONE.ENV) ? false : true;

  //   // update field above
  //   advancedCourierMode = String(courier.courier).toLowerCase() === 'book any';
  //   courierMode = advancedCourierMode ? 'advanced' : 'regular';

  //   let postOrder = {};
  //   if (isStandard) {
  //     const { pickUp, dropOff, orderId } = standardProps;
  //     const { senderDetails } = pickUp;
  //     const { recipientDetails } = dropOff;

  //     postOrder = {
  //       ...standardProps,
  //       refNo: orderId,
  //       userId: userInfo.currentUser.id,
  //       emailAddress: userInfo.currentUser.email,
  //       gcash: {
  //         ...location?.state?.gcash,
  //         refund: { success: false },
  //         status: statusPayment,
  //         pickupNow: standardProps.pickUpTime === 'PickUpNow',
  //         itemType: standardProps.itemType,
  //         pickupUnitFloor: senderDetails.unitFloorHouse,
  //         pickupBldgStreet: senderDetails.bldgStreet,
  //         dropOffUnitFloor: recipientDetails.unitFloorHouse,
  //         dropOffBldgStreet: recipientDetails.bldgStreet,
  //         declaredItemPrice: standardProps.itemPrice,
  //         cashBackAmount: standardProps?.cashBackAmount || 0,
  //         advancedCourierMode: userInfo.currentUser.advancedCourierMode,
  //         recordCreated: true,
  //         courierMode: 'regular',
  //         isProd,
  //       },
  //     };
  //   } else {
  //     const { pickUp, dropOff, orderId, courier } = onDemandProps;
  //     const { senderDetails } = pickUp;
  //     const { recipientDetails } = dropOff;

  //     advancedCourierMode = String(courier.courier).toLowerCase() === 'book any';
  //     courierMode = advancedCourierMode ? 'advanced' : 'regular';

  //     postOrder = {
  //       ...onDemandProps,
  //       refNo: orderId,
  //       userId: userInfo.currentUser.id,
  //       emailAddress: userInfo.currentUser.email,
  //       gcash: {
  //         ...location?.state?.gcash,
  //         refund: { success: false },
  //         status: statusPayment,
  //         pickupNow: onDemandProps.pickUpTime === 'PickUpNow',
  //         itemType: onDemandProps.itemType,
  //         pickupUnitFloor: senderDetails.unitFloorHouse,
  //         pickupBldgStreet: senderDetails.bldgStreet,
  //         dropOffUnitFloor: recipientDetails.unitFloorHouse,
  //         dropOffBldgStreet: recipientDetails.bldgStreet,
  //         declaredItemPrice: onDemandProps.itemPrice,
  //         tippingAmount: onDemandProps.driversTip || 0,
  //         voucherCode: onDemandProps?.voucherCode || '',
  //         parcelsCashbackUrl: `${DEPLOYMENT.PARCELS_API}/v1/parcelsCashback/add`,
  //         cashBackAmount: onDemandProps?.cashBackAmount || 0,
  //         cashbackPaymentDetails: onDemandProps.cashbackPaymentDetails,
  //         currentCourierRates: onDemandProps?.currentCourierRates || {},
  //         convenienceFee: CONVENIENCE_FEE.REGULAR,
  //         advancedCourierMode:
  //           onDemandProps.courier.courier === BOOK_ANY || userInfo.currentUser.advancedCourierMode
  //             ? true
  //             : false,
  //         recordCreated: true,
  //         courierMode: onDemandProps.courier.courier === BOOK_ANY ? 'advanced' : 'regular',
  //         isProd,
  //       },
  //     };
  //   }

  //   const { orderPayload, courierPayload } = createOrderPayload(postOrder, userInfo);

  //   let booking;

  //   booking = await OnDemandDAO.createOrder(orderPayload, courierPayload);

  //   console.log('Booking Result:', booking);

  //   const isOrderCreated = booking?.result?.success || booking?.success;

  //   console.log(`Order Created: ${JSON.stringify(isOrderCreated)}`);

  //   if (isOrderCreated) {
  //     const addressBook = onDemandProps.pickUp.senderDetails?.addressBook || {};

  //     /*
  //           update userInfo ( redux and real-time database)
  //           IF
  //             User
  //               No booking at all
  //           AND
  //             Booking is On-Demand
  //               previous booking courier mode and current booking courier mode is not the same
  //         */
  //     const isTriedBookedAny = onDemandProps?.courier?.courier === BOOK_ANY;
  //     const userInfoBookedAny = userInfo?.currentUser?.advancedCourierMode;

  //     const shouldUpdateUser = ({ currentUser }, isStandard) => {
  //       return (
  //         !currentUser?.recordCreated || (!isStandard && isTriedBookedAny !== userInfoBookedAny)
  //       );
  //     };

  //     message.info(`Update user standard`);
  //     if (shouldUpdateUser(userInfo, isStandard)) {
  //       const updatedUserInfo = {
  //         recordCreated: true,
  //         ...(!isStandard && {
  //           advancedCourierMode: isTriedBookedAny || userInfoBookedAny,
  //           courierMode: isTriedBookedAny ? 'advanced' : 'regular',
  //         }),
  //       };

  //       updateUserInfo(updatedUserInfo);
  //     }

  //     message.info(`Edit address book`);
  //     if (!isEmpty(addressBook)) {
  //       const addressDao = new AddressBookDAO();

  //       addressDao.editAddress(addressBook.id, userInfo.currentUser.id, {
  //         ...addressBook,
  //         recently: true,
  //       });

  //       !isEmpty(recentlyAddress) &&
  //         addressDao.editAddress(recentlyAddress.id, userInfo.currentUser.id, {
  //           ...recentlyAddress,
  //           recently: false,
  //         });
  //     }

  //     setHappyPath(true);
  //     setBookingCreated(true);
  //     setPaymentStatus(statusPayment);

  //     dispatch(setOnDemandInitialState());
  //     dispatch(setStandardInitialState());

  //     console.log(`navigate to payment - ${JSON.stringify(isOrderCreated)}`);

  //     return navigate(paymentStatusPagePath, {
  //       state: {
  //         status: isOrderCreated,
  //         isFoodPandaCourier: onDemandProps.courier.courier === COURIER.PANDAGO,
  //       },
  //     });
  //   }

  //   message.error('There is an error in if statement');
  //   // navigate(paymentStatusPagePath, {
  //   //   state: {
  //   //     status: 'SUCCESS',
  //   //   },
  //   // });

  //   // updateUserInfo({
  //   //   advancedCourierMode,
  //   //   recordCreated: true,
  //   //   courierMode,
  //   // });
  // };

  useEffect(() => {
    intervalInquiry();

    /*uncomment this for testing in local env. */
    // tempCreateOrder();
  }, [bookingCreated]);

  useEffect(() => {
    if (paymentStatus === 'SUCCESS') {
      setIsPaymentSuccess(true);
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (location?.state?.useCashBack && isPaymentSuccess) {
      const { courier, orderId } = onDemandProps;
      const payload = {
        userId: userInfo.currentUser.id || '',
        paymentAmount: courier?.rate,
        refNo: orderId,
      };

      (async () => {
        const paymentDao = new PaymentDAO();
        await paymentDao.getCashBack(payload);
      })();

      setIsPaymentSuccess(false);
    }
  }, [isPaymentSuccess]);

  const intervalInquiry = async () => {
    if (happyPath && bookingCreated) {
      return navigate(paymentStatusPagePath, {
        state: {
          status: bookingCreated,
        },
      });
    }

    const paymentReqId = location?.state?.gcash.paymentRequestId;
    const refNo = location?.state?.refNo;

    const paymentDao = new PaymentDAO();
    const inquiry = await paymentDao.gcashPaymentInquiry({
      userId: userInfo.currentUser.id,
      paymentRequestId: paymentReqId,
    });

    if (inquiry) {
      let postOrder = {};
      let courierMode = 'regular';
      let advancedCourierMode = false;

      const statusPayment = inquiry?.result?.paymentStatus || 'INITIATED';
      const isProd = STAGING_AND_PRE_PROD_ENV.includes(ZONE.ENV) ? false : true;

      if (isStandard) {
        const { pickUp, dropOff, orderId } = standardProps;
        const { senderDetails } = pickUp;
        const { recipientDetails } = dropOff;

        postOrder = {
          ...standardProps,
          refNo: orderId,
          userId: userInfo.currentUser.id,
          emailAddress: userInfo.currentUser.email,
          gcash: {
            ...location?.state?.gcash,
            refund: { success: false },
            status: statusPayment,
            pickupNow: standardProps.pickUpTime === 'PickUpNow',
            itemType: standardProps.itemType,
            pickupUnitFloor: senderDetails.unitFloorHouse,
            pickupBldgStreet: senderDetails.bldgStreet,
            dropOffUnitFloor: recipientDetails.unitFloorHouse,
            dropOffBldgStreet: recipientDetails.bldgStreet,
            declaredItemPrice: standardProps.itemPrice,
            cashBackAmount: standardProps?.cashBackAmount || 0,
            advancedCourierMode: userInfo.currentUser.advancedCourierMode,
            recordCreated: true,
            courierMode: 'regular',
            isProd,
          },
        };
      } else {
        const { pickUp, dropOff, orderId, courier } = onDemandProps;
        const { senderDetails } = pickUp;
        const { recipientDetails } = dropOff;

        advancedCourierMode = String(courier.courier).toLowerCase() === 'book any';
        courierMode = advancedCourierMode ? 'advanced' : 'regular';

        postOrder = {
          ...onDemandProps,
          refNo: orderId,
          userId: userInfo.currentUser.id,
          emailAddress: userInfo.currentUser.email,
          gcash: {
            ...location?.state?.gcash,
            refund: { success: false },
            status: statusPayment,
            pickupNow: onDemandProps.pickUpTime === 'PickUpNow',
            itemType: onDemandProps.itemType,
            pickupUnitFloor: senderDetails.unitFloorHouse,
            pickupBldgStreet: senderDetails.bldgStreet,
            dropOffUnitFloor: recipientDetails.unitFloorHouse,
            dropOffBldgStreet: recipientDetails.bldgStreet,
            declaredItemPrice: onDemandProps.itemPrice,
            tippingAmount: onDemandProps.driversTip || 0,
            voucherCode: onDemandProps?.voucherCode || '',
            parcelsCashbackUrl: `${DEPLOYMENT.PARCELS_API}/v1/parcelsCashback/add`,
            cashBackAmount: onDemandProps?.cashBackAmount || 0,
            cashbackPaymentDetails: onDemandProps.cashbackPaymentDetails,
            currentCourierRates: onDemandProps?.currentCourierRates || {},
            convenienceFee: CONVENIENCE_FEE.REGULAR,
            advancedCourierMode:
              onDemandProps.courier.courier === BOOK_ANY || userInfo.currentUser.advancedCourierMode
                ? true
                : false,
            recordCreated: true,
            courierMode: onDemandProps.courier.courier === BOOK_ANY ? 'advanced' : 'regular',
            isProd,
          },
        };
      }

      /*
        update user redux regarless if the booking payment is success or not
      */

      updateUserInfo({
        advancedCourierMode,
        recordCreated: true,
        courierMode,
      });

      const { gcash, userId } = postOrder;
      const { paymentId, paymentRequestId, paymentAmount } = gcash;

      if (statusPayment === 'SUCCESS') {
        const paymentDao = new PaymentDAO();
        await paymentDao.testPayload({ postOrder, userInfo });

        const { orderPayload, courierPayload } = createOrderPayload(postOrder, userInfo);

        let booking;
        try {
          booking = await OnDemandDAO.createOrder(orderPayload, courierPayload);
        } catch (err) {
          console.log(`Booking error - ${JSON.stringify(err)}`);
        }

        const { courier, orderId } = onDemandProps;

        let isCalledCasback = false;

        if (location?.state?.useCashBack && !isCalledCasback) {
          const payload = {
            userId: userInfo.currentUser.id || '',
            paymentAmount: courier?.rate,
            refNo: orderId,
          };
          await paymentDao.getCashBack(payload);
          isCalledCasback = true;
        }

        const isOrderCreated = booking?.result?.success || booking?.success;

        if (isOrderCreated) {
          const addressBook = onDemandProps.pickUp.senderDetails?.addressBook || {};
          /*
            update userInfo ( redux and real-time database)
            IF
              User
                No booking at all
            AND
              Booking is On-Demand
                previous booking courier mode and current booking courier mode is not the same
          */
          const isTriedBookedAny = onDemandProps?.courier?.courier === BOOK_ANY;
          const userInfoBookedAny = userInfo?.currentUser?.advancedCourierMode;

          const shouldUpdateUser = ({ currentUser }, isStandard) => {
            return (
              !currentUser?.recordCreated || (!isStandard && isTriedBookedAny !== userInfoBookedAny)
            );
          };

          if (shouldUpdateUser(userInfo, isStandard)) {
            const updatedUserInfo = {
              recordCreated: true,
              ...(!isStandard && {
                advancedCourierMode: isTriedBookedAny || userInfoBookedAny,
                courierMode: isTriedBookedAny ? 'advanced' : 'regular',
              }),
            };

            updateUserInfo(updatedUserInfo);
          }

          if (!isEmpty(addressBook)) {
            const addressDao = new AddressBookDAO();

            addressDao.editAddress(addressBook.id, userInfo.currentUser.id, {
              ...addressBook,
              recently: true,
            });

            !isEmpty(recentlyAddress) &&
              addressDao.editAddress(recentlyAddress.id, userInfo.currentUser.id, {
                ...recentlyAddress,
                recently: false,
              });
          }

          setHappyPath(true);
          setBookingCreated(isOrderCreated);
          setPaymentStatus(statusPayment);

          dispatch(setOnDemandInitialState());
          dispatch(setStandardInitialState());

          return navigate(paymentStatusPagePath, {
            state: {
              status: isOrderCreated,
              isFoodPandaCourier: onDemandProps.courier.courier === COURIER.PANDAGO,
            },
          });
        } else {
          await paymentDao.refund({
            paymentId,
            orderNo: refNo,
            paymentRequestId,
            refundAmount: {
              currency: paymentAmount.currency,
              value: paymentAmount.value,
            },
            userId,
            refundReason: 'The user cancels the order due to some reason.',
          });

          /*
            save to mongoDB for refund purposes
          */
          cancelledTransaction(statusPayment, {
            userId: userInfo.currentUser.id,
            orderDetails: JSON.stringify({
              ...postOrder,
              gcash: {
                ...postOrder.gcash,
                extendInfo: JSON.stringify(booking?.message || ''),
              },
            }),
            status: statusPayment,
          });
        }
      } else if (statusPayment === 'FAIL' || statusPayment === 'CANCELLED') {
        /*
          save to mongoDB
        */
        cancelledTransaction(statusPayment, {
          userId: userInfo.currentUser.id,
          orderDetails: JSON.stringify(postOrder),
          status: statusPayment,
        });
      } else {
        await new Promise(resolve => setTimeout(resolve, 2500));
        intervalInquiry();
      }
    }
  };

  const updateUserInfo = async user => {
    const updatedUser = {
      ...userInfo.currentUser,
      ...user,
    };
    setCurrentUser(updatedUser);
    const loginDao = new Login();
    await loginDao.updateUser(userInfo.currentUser.id, updatedUser);
  };

  const cancelledTransaction = async (paymentStatus, order) => {
    const PaymentDao = new PaymentDAO();
    const transaction = await PaymentDao.pushTransaction(order);

    const user = userInfo.currentUser;
    const cancelledReason = `Failed payment - status: ${paymentStatus}`;

    const { gcash } = order;

    await PaymentDao.refund({
      postId: order?.postId || null,
      mdbId: order?.mdbId || null,
      orderNo: order.refNo,
      paymentId: gcash.paymentId,
      paymentRequestId: gcash.paymentRequestId,
      refundAmount: gcash.paymentAmount,
      contactNumber: user.gcashNumber,
      name: `${user.firstName} ${user.lastName}`,
      userId: user.id,
      refundReason: cancelledReason || DEFAULT_REASON,
    });

    if (transaction) {
      setHappyPath(true);
      setBookingCreated(true);
      setPaymentStatus(paymentStatus);

      dispatch(setOnDemandInitialState());
      dispatch(setStandardInitialState());

      return navigate(paymentStatusPagePath, {
        state: {
          status: bookingCreated,
        },
      });
    }
  };
  return <PlacingOrder />;
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoader,
      setCurrentUser,
    },
    dispatch
  );
}
const mapStateToProps = state => ({
  onDemandProps: state.onDemandReducer,
  standardProps: state.standardReducer,
  onDemandPaymentRequestId: state.onDemandReducer.paymentRequestId,
  standardPaymentRequestId: state.standardReducer.paymentRequestId,
  userInfo: state.usersReducer,
  recentlyAddress: state.addressReducer.recentlyAddress,
  view: state.homeReducer.view,
});
export default connect(mapStateToProps, matchDispatchToProps)(PaymentProcess);
